.golo-toaster::part(container) {
	// here you can include styles for the whole container
	flex-wrap: wrap;
	justify-content: flex-end;
}

.golo-toaster::part(container)::nth-chd {
	// here you can include styles for the whole container
	flex-wrap: wrap;
}

.golo-toaster::part(header) {
	font-size: 1.1rem;
	font-weight: bold;
}

.golo-toaster::part(message) {
	font-size: 1rem;
	min-width: 16rem;
}

.golo-toaster::part(button) {
	margin: 0.25rem;
	color: var(--ion-color-toast-contrast);
}

.toast-button {
	color: var(--ion-color-toast-contrast);
}
