/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import '~@ionic/angular/css/core.css';

/* Basic CSS for apps built with Ionic */
@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/structure.css';
@import '~@ionic/angular/css/typography.css';
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import '~@ionic/angular/css/padding.css';
@import '~@ionic/angular/css/float-elements.css';
@import '~@ionic/angular/css/text-alignment.css';
@import '~@ionic/angular/css/text-transformation.css';
@import '~@ionic/angular/css/flex-utils.css';

ion-popover {
	ion-backdrop {
		opacity: 0.5 !important;
	}
	.popover-wrapper {
		.popover-content {
			width: 80vw;
			max-width: 500px;
		}
	}
}

.popover-content {
	--background: var(--ion-color-listItems) !important;
}

ion-modal.golo-main-modal {
	.modal-wrapper {
		height: 100% !important;
		width: 100% !important;
	}
}

.promote-install-ios-svg {
	max-height: 1.5rem;
	max-width: 1.5rem;
}

.sc-ion-modal-md-h:last-of-type {
	--backdrop-opacity: var(--ion-backdrop-opacity, 0.32);
}

.sc-ion-modal-ios-h:last-of-type {
	--backdrop-opacity: var(--ion-backdrop-opacity, 0.32);
}

ion-content.promote-content {
	display: flex;
}

ion-content {
	--background: var(--ion-color-background) var(--background-texture-image);
	--padding-bottom: 5rem;
}

.app-background {
	background: var(--ion-color-background) var(--background-texture-image);
}

ion-menu {
	--background: var(--ion-color-menu);
	--color: var(--ion-color-menu-contrast);
}

ion-card {
	--background: var(--ion-color-cards);
	--color: var(--ion-color-cards-contrast);
}

ion-card.md ion-item {
	--background: var(--ion-color-cards);
	--color: var(--ion-color-cards-contrast);
}

ion-badge {
	font-weight: normal;
}

ion-card.md ion-list-header {
	--background: var(--ion-color-cards);
	--color: var(--ion-color-cards-contrast);
}

ion-card.md ion-toolbar {
	--background: var(--ion-color-cards);
	--color: var(--ion-color-cards-contrast);
}

img {
	display: block;
}

.global-footer {
	cursor: pointer;
	position: fixed;
	bottom: 0;
	width: 100%;
	max-width: calc(100% - 24px);
	border-top-left-radius: 4px;
	border-bottom-left-radius: 4px;
	border-top-right-radius: 4px;
	border-bottom-right-radius: 4px;
	margin: 12px;
	opacity: 0.9;
	box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px,
		rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;
}

.global-footer-button {
	color: var(--ion-color-primary-contrast);
	font-size: var(--gaap-font-size-l);
}

ion-card.md ion-input {
	--background: var(--ion-color-cards);
	--color: var(--ion-color-cards-contrast);
}

.card-list {
	background-color: #00000000;
	color: var(--ion-color-cards-contrast);
}

ion-list-header {
	--background: var(--ion-color-listHeader);
	--color: var(--ion-color-listHeader-contrast);
}

ion-card.md ion-list-header {
	--background: #00000000;
	--color: var(--ion-color-listHeader-contrast);
}

ion-list-header.md ion-label {
	--color: var(--ion-color-listHeader-contrast);
}

ion-card.md ion-item {
	--color: var(--ion-color-card-contrast);
	--background: #00000000;
}

ion-card.md ion-input {
	--color: var(--ion-color-card-contrast);
	--background: #00000000;
}

ion-item {
	--background: var(--ion-color-listItems);
	--color: var(--ion-color-listItems-contrast);
	cursor: pointer;
}

ion-list {
	--background: var(--ion-color-listItems);
	--color: var(--ion-color-listItems-contrast);
}

ion-list.md ion-input {
	--color: var(--ion-color-listItems-contrast);
	--background: #00000000;
}

input:read-only {
	opacity: 0.7;
}

ion-chip {
	--background: var(--ion-color-chipsDefault);
	--color: var(--ion-color-chipsDefault-contrast);
}

.selectedChip {
	background-color: var(--ion-color-chipsSelected);
	color: var(--ion-color-chipsSelected-contrast);
}

.list-background {
	background: #0000;
	color: var(--ion-color-listItems-contrast);
	margin: 0 0.5rem 0 0.5rem;
}

ion-segment {
	--background: var(--ion-color-tabs);
	--color: var(--ion-color-tabs-contrast);
	--background-checked: var(--ion-color-tabs-shade);
	--color-checked: var(--ion-color-tabs-contrast);
}

ion-segment-button {
	--background: var(--ion-color-tabs);
	--color: var(--ion-color-tabs-contrast);
	--background-checked: var(--ion-color-tabs-shade);
	--color-checked: var(--ion-color-tabs-contrast);
}

ion-segment-button.md ion-label {
	color: var(--ion-color-list-tabs-contrast);
}

ion-toggle {
	--background: var(--ion-color-secondary-tint);
	--color: var(--ion-color-secondary-contrast);
	--background-checked: var(--ion-color-secondary);
	--handle-background: var(--ion-color-secondary-contrast);
	--handle-background-checked: var(--ion-color-secondary-contrast);
}

ion-toast {
	--background: var(--ion-color-toast);
	--color: var(--ion-color-toast-contrast);
}

ion-toast.md ion-button {
	color: var(--ion-color-toast-contrast);
}

ion-loading {
	--background: var(--ion-color-toast);
	--spinner-color: var(--ion-color-toast-contrast);
}

.loader {
	--background: var(--ion-color-toast);
	--spinner-color: var(--ion-color-toast-contrast);
	color: var(--ion-color-toast-contrast);
	--backdrop-opacity: 20%;
}

.global-name {
	font-size: var(--gaap-font-size-l);
	color: var(--ion-color-dark);
}

.global-details {
	font-size: var(--gaap-font-size-m);
	color: var(--ion-color-medium);
}

ion-card-title {
	font-size: var(--gaap-font-size-l);
	color: var(--ion-color-dark);
}

ion-card-subtitle {
	font-size: var(--gaap-font-size-m);
	color: var(--ion-color-medium);
}

.badge-warning {
	--background: var(--ion-color-warning);
	--color: var(--ion-color-warning-contrast);
}

.badge-success {
	--background: var(--ion-color-success);
	--color: var(--ion-color-success-contrast);
}

.badge-danger {
	--background: var(--ion-color-danger);
	--color: var(--ion-color-danger-contrast);
}

.badge-tertiary {
	--background: var(--ion-color-tertiary);
	--color: var(--ion-color-tertiary-contrast);
}

.badge-secondary {
	--background: var(--ion-color-secondary);
	--color: var(--ion-color-secondary-contrast);
}

.offline-icon {
	font-size: 24px;
	padding-right: 0.25rem;
}

.bread-crumb {
	opacity: 0.5;
	font-weight: bold;
	font-size: var(--gaap-font-size-l);
}

.prep-name {
	color: var(--ion-color-dark);
	font-size: var(--gaap-font-size-m);
}

@media (max-width: 450px) {
	.title{
		font-size: 15px !important;
		padding: 0px;
	}
}